<template>
  <v-row class="fill-height">
    <v-container class="fill-height justify-center my-auto" fluid>
      <v-card width="400">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-img src="@/assets/logo.png" max-width="250" contain max-height="150">
          </v-img>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-progress-linear v-show="loading" indeterminate color="primary" background-opacity="0">
        </v-progress-linear>
        <v-card-text>
          <v-fade-transition>
            <v-form v-if="appData.initiated" ref="logInForm" lazy-validation class="px-6">
              <v-text-field v-model="logInForm.email" label="E-mail" :rules="rules.emailRules">
              </v-text-field>
              <v-text-field v-model="logInForm.password" :type="passwordFieldType" label="Password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="toggleshowPassword"
                :rules="rules.passwordRule" @keyup.enter="login">
              </v-text-field>
              <v-btn color="primary" dark block large @click="login" @keyup.enter="login">
                Admin Login
              </v-btn>
            </v-form>
            <v-form v-else ref="signUpForm" lazy-validation class="px-6">
              <v-text-field v-model="signUpForm.name" label="Full Name" :rules="rules.nameRules" required>
              </v-text-field>
              <v-text-field v-model="signUpForm.email" label="E-mail" :rules="rules.emailRules" required>
              </v-text-field>
              <v-text-field v-model="signUpForm.password" :type="passwordFieldType" label="Password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="toggleshowPassword"
                :rules="rules.passwordRule" required>
              </v-text-field>
              <v-text-field v-model="confirmPassword" :type="passwordFieldType" label="Confirm Password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="toggleshowPassword"
                :rules="rules.confirmPasswordRule" required @keyup.enter="createAccount">
              </v-text-field>
              <v-btn color="primary" dark block large @click="createAccount" @keyup.enter="createAccount">
                Create Admin Account
              </v-btn>
            </v-form>
          </v-fade-transition>
        </v-card-text>
      </v-card>
    </v-container>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Admin',
  data() {
    return {
      showPassword: false,
      passwordFieldType: 'password',
      loading: false,
      logInForm: {
        email: '',
        password: '',
      },
      signUpForm: {
        name: '',
        email: '',
        password: '',
        userRole: 'Super Admin',
      },
      confirmPassword: '',
      rules: {
        nameRules: [
          v => !!v || 'Full name is required',
        ],
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        passwordRule: [
          v => !!v || 'Password is required',
          v => (v && v.length >= 8) || 'Password must be 8 characters long',
        ],
        confirmPasswordRule: [
          v => !!v || 'Password is required',
          v => (v && v.length >= 8) || 'Password must be 8 characters long',
          v => (v && v === this.signUpForm.password) || 'Password must match',
        ]
      }
    }
  },
  methods: {
    createAccount() {
      if (this.$refs.signUpForm.validate()) {
        this.loading = true;
        this.$store.dispatch('createAdminAccount', this.signUpForm)
          .then((response) => {
            this.loading = false;
            this.showPassword = false;
            this.$store.dispatch('showSnackBar', {
              text: response,
              color: 'success',
            })
          },
            error => {
              this.loading = false;
              this.$store.dispatch('showSnackBar', {
                text: error,
                color: 'error',
              })
            })
      }
    },
    login() {
      if (this.$refs.logInForm.validate()) {
        this.loading = true;
        this.$store.dispatch('login', this.logInForm)
          .then(response => {
            this.loading = false;
            this.showPassword = false;
            this.$store.dispatch('showSnackBar', {
              text: response,
              color: 'success',
            })
          }, error => {
            this.loading = false;
            this.$store.dispatch('showSnackBar', {
              text: error,
              color: 'error'
            })
          })
      }
    },
    toggleshowPassword() {
      this.showPassword = !this.showPassword;
      this.passwordFieldType = (this.showPassword) ? 'text' : 'password';
    },
  },
  computed: {
    ...mapGetters(['appData']),
  },
}

</script>

<style>

</style>